import * as React from 'react'
import {BUTTON_DOWNLOAD_TICKETS} from '../../../config/data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {DownloadTicketsButtonProps} from './index'

export const DownloadTicketsButton = ({mobile, downloadTicketsForEvent, event, t}: DownloadTicketsButtonProps) => (
  <EventLink
    secondary={mobile}
    mobile={mobile}
    onClick={() => downloadTicketsForEvent(event)}
    data-hook={BUTTON_DOWNLOAD_TICKETS}
  >
    {t('events.buttons.download-tickets')}
  </EventLink>
)
