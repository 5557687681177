import * as React from 'react'
import {BUTTON_CANCEL_RSVP} from '../../../config/data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {CancelRsvpButtonProps} from './index'

export const CancelRsvpButton = ({event, mobile, cancelRsvp, t}: CancelRsvpButtonProps) => (
  <EventLink secondary={mobile} mobile={mobile} onClick={() => cancelRsvp(event)} data-hook={BUTTON_CANCEL_RSVP}>
    {t('events.buttons.cancel-rsvp')}
  </EventLink>
)
