import * as React from 'react'
import {BUTTON_GO_TO_DETAILS} from '../../../config/data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {DetailsButtonProps} from './index'

export const DetailsButton = ({mobile, event, navigateToDetailsPage, t}: DetailsButtonProps) => (
  <EventLink
    primary={mobile}
    mobile={mobile}
    as="a"
    onClick={() => navigateToDetailsPage(event)}
    data-hook={BUTTON_GO_TO_DETAILS}
  >
    {t('events.buttons.go-to-details')}
  </EventLink>
)
