import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../../commons/selectors/environment'
import {navigateToDetailsPage} from '../../../actions/navigation'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {DetailsButton as DetailsButtonPresentation} from './details-button'

export interface DetailsButtonProps extends DetailsButtonOwnProps, DetailsButtonRuntimeProps, WithNamespaces {}

interface DetailsButtonOwnProps {
  event: DetailedEvent
}

interface DetailsButtonRuntimeProps {
  mobile: boolean
  navigateToDetailsPage: typeof navigateToDetailsPage
}

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  navigateToDetailsPage: actions.navigateToDetailsPage
})

export const DetailsButton = connect<DetailsButtonOwnProps, DetailsButtonRuntimeProps>(mapRuntime)(
  withNamespaces()(DetailsButtonPresentation)
)
