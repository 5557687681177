import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../../commons/selectors/environment'
import {downloadTicketsForEvent} from '../../../services/tickets'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {DownloadTicketsButton as DownloadTicketsButtonPresentation} from './download-tickets-button'

export interface DownloadTicketsButtonProps
  extends DownloadTicketsButtonOwnProps,
    DownloadTicketsButtonRuntimeProps,
    WithNamespaces {}

interface DownloadTicketsButtonOwnProps {
  event: DetailedEvent
}

interface DownloadTicketsButtonRuntimeProps {
  mobile: boolean
  downloadTicketsForEvent: typeof downloadTicketsForEvent
}

const mapRuntime = ({state}: MemberPageContext) => ({
  mobile: isMobile(state),
  downloadTicketsForEvent
})

export const DownloadTicketsButton = connect<DownloadTicketsButtonOwnProps, DownloadTicketsButtonRuntimeProps>(
  mapRuntime
)(withNamespaces()(DownloadTicketsButtonPresentation))
