import * as classNames from 'classnames'
import * as React from 'react'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import {TextButton} from 'wix-ui-tpa/TextButton'
import s from './event-link.st.css'

interface EventLinkProps {
  children: any
  onClick?: any
  href?: string
  as?: string
  primary?: boolean
  secondary?: boolean
  mobile?: boolean
}

export const EventLink = (props: EventLinkProps) => {
  const {primary, secondary, mobile, ...rest} = props
  if (primary) {
    return (
      <Button
        {...rest}
        className={classNames(s.primaryButton, {[s.mobile]: mobile})}
        priority={PRIORITY.basic}
        fullWidth={mobile}
      />
    )
  } else if (secondary) {
    return (
      <Button
        {...rest}
        className={classNames(s.secondaryButton, {[s.mobile]: mobile})}
        priority={PRIORITY.secondary} // TODO secondaryBasic, once it exists
        fullWidth={mobile}
      />
    )
  }
  return <TextButton {...rest} className={classNames(s.link, {[s.mobile]: mobile})} />
}
