import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale, isMobile} from '../../../commons/selectors/environment'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventSummary as EventSummaryPresentation} from './event-summary'

export interface EventSummaryProps extends EventSummaryOwnProps, EventSummaryRuntimeProps, WithNamespaces {}

interface EventSummaryOwnProps {
  event: DetailedEvent
}

interface EventSummaryRuntimeProps {
  locale: string
  mobile: boolean
}

const mapRuntime = ({state}: MemberPageContext): EventSummaryRuntimeProps => ({
  locale: getFullLocale(state),
  mobile: isMobile(state)
})

export const EventSummary = connect<EventSummaryOwnProps, EventSummaryRuntimeProps>(mapRuntime)(
  withNamespaces()(EventSummaryPresentation)
)
