import {EventStatus} from '@wix/wix-events-commons-statics/dist/types/enums/events'

export const ROOT_COMPONENT_ID = 'events-members-root'

export const EXPERIMENTS_SCOPE = 'wix-events-ui'
export const MEMORY_CACHE_KEY = 'events-members-page-store'

export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'

export const UPCOMING_EVENT_STATUSES = [EventStatus.SCHEDULED, EventStatus.STARTED]
export const PAST_EVENT_STATUSES = [EventStatus.ENDED, EventStatus.CANCELED]
