import {getElementHeight} from '../../commons/utils/dom'
import {setHeight} from '../../commons/utils/wix-sdk'
import {ROOT_COMPONENT_ID} from '../config/constants'
import {MemberPageState} from '../types/state'

export const resizeHeight = (state: MemberPageState) => {
  const height = getCurrentContentHeight(state)
  setHeight(height)
}
const getCurrentContentHeight = (state: MemberPageState) =>
  getElementHeight(`#${ROOT_COMPONENT_ID}`, getContainer(state))

const getContainer = (state: MemberPageState) => document.querySelector(state.component.id)
