import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../commons/selectors/environment'
import {MembersTexts} from '../../../commons/types/members-settings'
import {getEvents, setTab} from '../../actions/events'
import {getUserText} from '../../selectors/component'
import {MemberPageContext, Tabs} from '../../types/state'
import {connect} from '../connect'
import {EventsTabs as EventsTabsPresentation} from './events-tabs'

export interface EventTabsProps extends EventTabsRuntimeProps, WithNamespaces {}

interface EventTabsRuntimeProps {
  setTab: typeof setTab
  tab: Tabs
  getEvents: typeof getEvents
  pastEventsTabLabel: string
  upcomingEventsTabLabel: string
  mobile: boolean
}

const mapRuntime = ({state, actions}: MemberPageContext): EventTabsRuntimeProps => ({
  getEvents: actions.getEvents,
  setTab: actions.setTab,
  tab: state.tab,
  pastEventsTabLabel: getUserText(state, MembersTexts.pastEventsTab),
  upcomingEventsTabLabel: getUserText(state, MembersTexts.upcomingEventsTab),
  mobile: isMobile(state)
})

export const EventsTabs = connect<{}, EventTabsRuntimeProps>(mapRuntime)(withNamespaces()(EventsTabsPresentation))
