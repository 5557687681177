import * as classNames from 'classnames'
import * as React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import s from './expand-icon.scss'

interface ExpandIconProps {
  expanded?: boolean
  right?: boolean
  onClick: () => void
  mobile?: boolean
  extraClass?: string
  href?: string
}

export const ExpandIcon = ({expanded, right, onClick, extraClass, mobile, href}: ExpandIconProps) => (
  <a href={href} onClick={href ? null : onClick}>
    <ChevronDown
      size={mobile ? 14 : 16}
      viewBox="8 7 9 10"
      className={classNames(s.root, {[s.up]: expanded, [s.right]: right}, extraClass ? extraClass : '')}
    />
  </a>
)
