import {DetailedEvent, MemberPageState} from '../types/state'
import {isOwnProfile} from './user'
import {isDemoMode, isNoEventsMode} from './view'

export const shouldLoadMore = (state: MemberPageState) => state.events.length < state.eventsTotal && !state.loading

export const hasNoEvents = (state: MemberPageState): boolean => isNoEventsMode(state) || !getEvents(state).length

export const getEvents = (state: MemberPageState): DetailedEvent[] =>
  isDemoMode(state) && state.events.length === 0 && isOwnProfile(state) ? state.demoEvents : state.events

export const getEventBySlug = (state: MemberPageState, slug: string): DetailedEvent =>
  getEvents(state).find(event => event.event.slug === slug)
