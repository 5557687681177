import {withNamespaces, WithNamespaces} from 'react-i18next'
import {internalNavigate} from '../../../actions/navigation'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {GoToOrderButton as GoToOrderButtonPresentation} from './go-to-order-button'

export interface GoToOrderButtonProps extends GoToOrderButtonOwnProps, GoToOrderButtonRuntimeProps, WithNamespaces {}

interface GoToOrderButtonOwnProps {
  event: DetailedEvent
}

interface GoToOrderButtonRuntimeProps {
  internalNavigate: typeof internalNavigate
}

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  internalNavigate: actions.internalNavigate
})

export const GoToOrderButton = connect<GoToOrderButtonOwnProps, GoToOrderButtonRuntimeProps>(mapRuntime)(
  withNamespaces()(GoToOrderButtonPresentation)
)
