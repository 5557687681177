import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../../commons/selectors/environment'
import {shareEvent} from '../../../actions/events'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {ShareButton as ShareButtonPresentation} from './share-button'

export interface ShareButtonProps extends ShareButtonOwnProps, ShareButtonRuntimeProps, WithNamespaces {}

interface ShareButtonOwnProps {
  event: DetailedEvent
}

interface ShareButtonRuntimeProps {
  mobile: boolean
  shareEvent: typeof shareEvent
}

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  shareEvent: actions.shareEvent
})

export const ShareButton = connect<ShareButtonOwnProps, ShareButtonRuntimeProps>(mapRuntime)(
  withNamespaces()(ShareButtonPresentation)
)
