import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../commons/selectors/environment'
import {getTimeZoneId} from '../../../commons/selectors/event'
import {internalNavigate} from '../../actions/navigation'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {MobileOrder as MobileOrderPresentation} from './mobile-order'

export interface MobileOrderProps extends MobileOrderRuntimeProps, WithNamespaces {}

interface MobileOrderRuntimeProps {
  event: DetailedEvent
  order: wix.events.ticketing.Order
  locale: string
  timezone: string
  internalNavigate: typeof internalNavigate
}

const mapRuntime = ({state, actions}: MemberPageContext): MobileOrderRuntimeProps => {
  const event = state.navigation.routeParams.event
  return {
    event,
    order: state.navigation.routeParams.order,
    locale: getFullLocale(state),
    timezone: getTimeZoneId(event.event),
    internalNavigate: actions.internalNavigate
  }
}

export const MobileOrder = connect<{}, MobileOrderRuntimeProps>(mapRuntime)(withNamespaces()(MobileOrderPresentation))
