import {isMobile} from '../../../commons/selectors/environment'
import {toggleEventDetails} from '../../actions/events'
import {getEvents, hasNoEvents} from '../../selectors/events'
import {isOwnProfile} from '../../selectors/user'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventsList as EventsListPresentation} from './events-list'

export interface EventsListProps extends EventsListRuntimeProps {}

interface EventsListRuntimeProps {
  hasNoEvents: boolean
  events: DetailedEvent[]
  expandedEvents: string[]
  loading: boolean
  isOwner: boolean
  mobile: boolean
  toggleEventDetails: typeof toggleEventDetails
}

const mapRuntime = ({state, actions}: MemberPageContext): EventsListRuntimeProps => ({
  hasNoEvents: hasNoEvents(state),
  expandedEvents: state.expandedEvents,
  toggleEventDetails: actions.toggleEventDetails,
  loading: state.loading,
  isOwner: isOwnProfile(state),
  mobile: isMobile(state),
  events: getEvents(state)
})

export const EventsList = connect<{}, EventsListRuntimeProps>(mapRuntime)(EventsListPresentation)
